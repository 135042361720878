import cx from "classnames";
import Image from "next/image";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProducts,
  getProducts,
  updateProductRecommendations,
} from "../../../redux/slices/productsSlice";
import { getCurrentSite } from "../../../redux/slices/sitesSlice";
import ProductSelector from "../../atoms/ProductSelector/ProductSelector";
import Spinner from "../../atoms/Spinner/Spinner";
import styles from "./ProductsTable.module.scss";

const ProductsTableRow = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentSite = useSelector(getCurrentSite);
  const [currentProduct, setCurrentProduct] = useState(product);
  const dispatch = useDispatch();

  const sendSaveRequest = useCallback(() => {
    dispatch(
      updateProductRecommendations({
        siteId: currentSite.id,
        productId: currentProduct.id,
        recommendedProductIds: currentProduct.recommendedProducts.map(
          (p) => p.id
        ),
      })
    );
  }, [currentProduct, currentSite]);

  const setProductRecommendation = useCallback(
    (index, newProduct) => {
      const firstPartArr = currentProduct.recommendedProducts.slice(0, index);
      const secondPartArr = currentProduct.recommendedProducts.slice(index + 1);
      setCurrentProduct({
        ...currentProduct,
        recommendedProducts: [...firstPartArr, newProduct, ...secondPartArr],
      });
    },
    [currentProduct]
  );

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Image
              width={35}
              height={35}
              className="h-10 w-10 rounded-full border"
              src={currentProduct.image}
              alt={currentProduct.name}
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {currentProduct.name}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 max-w-md whitespace-nowrap">
        {isOpen ? (
          <>
            {currentProduct.recommendedProducts.map(
              (recommendedProduct, index) => (
                <div key={recommendedProduct.id} className="py-2">
                  <ProductSelector
                    parentProduct={currentProduct}
                    defaultProduct={recommendedProduct}
                    onClose={(newProduct) =>
                      setProductRecommendation(index, newProduct)
                    }
                  />
                </div>
              )
            )}
            <div className="py-2">
              <ProductSelector
                parentProduct={currentProduct}
                defaultProduct={null}
                onClose={(newProduct) =>
                  setProductRecommendation(
                    currentProduct.recommendedProducts.length + 1,
                    newProduct
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            {!currentProduct.recommendedProducts?.length && (
              <span className="text-sm text-gray-900 flex items-center">
                No recommended products
              </span>
            )}
            <div className="flex items-center">
              <div className="flex flex-shrink-0">
                {currentProduct.recommendedProducts.map(
                  (recommendedProduct, index) => (
                    <Image
                      key={recommendedProduct.id}
                      width={35}
                      height={35}
                      className={cx(
                        "h-10 w-10 bg-white rounded-full border",
                        index !== 0 && styles.overlappingImages
                      )}
                      src={recommendedProduct.image}
                      alt={recommendedProduct.name}
                    />
                  )
                )}
              </div>
              <div className="ml-2 flex-grow">
                <span className="text-sm text-gray-900">
                  {currentProduct.recommendedProducts
                    .map((recommendedProduct) => recommendedProduct.name)
                    .join(", ")}
                </span>
              </div>
            </div>
          </>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span
          className={cx(
            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
            {
              "bg-green-100": !product.isLoading,
              "text-green-800": !product.isLoading,
              "bg-blue-500": product.isLoading,
              "text-white": product.isLoading,
            }
          )}
        >
          {product.isLoading ? "Saving..." : "In Sync"}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        {isOpen ? (
          <button
            type="button"
            onClick={() => {
              sendSaveRequest();
              setIsOpen(!isOpen);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Save
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Edit
          </button>
        )}
      </td>
    </tr>
  );
};

type ProductsTableProps = {};

const ProductsTable: FC<ProductsTableProps> = () => {
  const dispatch = useDispatch();
  const { products, isLoadingProducts, error } = useSelector(getProducts);
  const currentSite = useSelector(getCurrentSite);
  useEffect(() => {
    if (currentSite?.id) {
      dispatch(fetchProducts(currentSite.id));
    }
  }, [currentSite]);

  if (isLoadingProducts) {
    return (
      <div className="flex items-center justify-center mb-2">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center mb-2">
        <span>{JSON.stringify(error)}</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col px-4 py-5 mx-auto md:max-w-full lg:max-w-screen-xl lg:px-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Frequently bought together
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products?.map((product) => (
                  <ProductsTableRow key={product.id} product={product} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
