import React, { FC } from "react";

import Spinner from "../Spinner/Spinner";

type LoadingProps = {};

const Loading: FC<LoadingProps> = () => (
  <div className="w-full h-full flex flex-col justify-center items-center">
    <Spinner size="l" />
    <p className="mt-3 text-lg">Loading...</p>
  </div>
);

export default Loading;
