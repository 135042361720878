import Image from "next/image";
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { getProducts } from "../../../redux/slices/productsSlice";
import { Product } from "../../../types/types";

type SiteSelectorProps = {
  parentProduct: Product;
  defaultProduct: Product;
  // eslint-disable-next-line no-unused-vars
  onClose: (newProduct: Product) => any;
};

const ProductSelector: FC<SiteSelectorProps> = ({
  parentProduct,
  defaultProduct,
  onClose,
}) => {
  const { products } = useSelector(getProducts);
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const container = useRef(null);

  const elementsToRemove = new Set(
    parentProduct.recommendedProducts?.map((p) => p.id)
  );

  const filteredProducts = products
    ?.filter((p) => !elementsToRemove.has(p.id))
    .filter(
      (product) =>
        product.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!container.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, container]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!show) return;

      if (event.key === "Escape") {
        setShow(false);
      }
    };

    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [show]);

  return (
    <div ref={container} className="bg-white">
      <div className="relative inline-block text-left">
        <button
          className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 rounded-md shadow-sm"
          type="button"
          onClick={() => setShow(!show)}
        >
          {defaultProduct?.name && (
            <Image
              height={21}
              width={21}
              className="h-6 w-6 rounded-full border"
              src={defaultProduct?.image}
              alt={defaultProduct?.name || "Select a product"}
            />
          )}
          <span className="ml-2">
            {defaultProduct?.name || "Select a product"}
          </span>
          <svg
            className="w-5 h-5 ml-2 -mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>{" "}
        {show && (
          <ul className="bg-white absolute mt-2 max-h-80 overflow-scroll text-gray-700 z-30 border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
            <li>
              <input
                type="text"
                className="mx-2 my-2 px-2 mr-4 py-1 border rounded-md"
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </li>
            {filteredProducts?.map((product) => (
              <li key={product.id}>
                <button
                  type="button"
                  key={product.name}
                  onClick={() => {
                    onClose(product);
                    setShow(false);
                  }}
                  className="text-gray-700 flex w-full px-4 py-2 text-sm leading-5 text-left"
                >
                  <Image
                    height={21}
                    width={21}
                    className="h-6 w-6 rounded-full border"
                    src={product.image}
                    alt={product.name}
                  />
                  <span className="ml-2">{product.name}</span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
