import React, { FC } from "react";

import withWebflowAuth from "../../../lib/withWebflowAuth";
import NavBar from "../../atoms/NavBar/NavBar";
import ProductsTable from "../../molecules/ProductsTable/ProductsTable";

type DashboardPageProps = {};

const DashboardPage: FC<DashboardPageProps> = () => (
  <>
    <NavBar />
    <ProductsTable />
  </>
);

export default withWebflowAuth(DashboardPage);
