import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../components/atoms/Loading/Loading";
import NoTeamScreen from "../components/molecules/NoTeamScreen/NoTeamScreen";
import SyncSitesPage from "../components/molecules/SyncSitesPage/SyncSitesPage";
import WebflowAuth from "../components/molecules/WebflowAuth/WebflowAuth";
import { fetchSites } from "../redux/slices/sitesSlice";
import { clearUser, fetchUser, getUserState } from "../redux/slices/userSlice";

const withWebflowAuth = (Component) => () => {
  const { user, isLoadingUser, error } = useSelector(getUserState);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    if (!user || !isLoadingUser) {
      dispatch(fetchUser());
    }
  }, []);
  useEffect(() => {
    if (user?.team?.lastSyncAt) {
      dispatch(fetchSites());
    }
  }, [user]);
  useEffect(() => {
    if (error) {
      dispatch(clearUser());
      router.push("/login");
    }
  }, [error]);

  if (!user || isLoadingUser) {
    return <Loading />;
  }
  if (!user.team) {
    return <NoTeamScreen />;
  }
  if (!user.hasOauth) {
    return <WebflowAuth />;
  }
  if (!user.team.lastSyncAt) {
    return <SyncSitesPage />;
  }
  return <Component />;
};

export default withWebflowAuth;
