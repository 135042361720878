import React, { FC } from "react";

import Button from "../../atoms/Button/Button";

type WebflowAuthProps = {};

const WebflowAuth: FC<WebflowAuthProps> = () => (
  <div className="max-w-screen-md mx-auto h-full flex items-center justify-center">
    <Button
      href={`https://webflow.com/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_WEBFLOW_CLIENT_ID}&response_type=code`}
    >
      Connect with Webflow
    </Button>
  </div>
);

export default WebflowAuth;
