import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createTeam, getUserState } from "../../../redux/slices/userSlice";
import Button from "../../atoms/Button/Button";

type NoTeamScreenProps = {};

const NoTeamScreen: FC<NoTeamScreenProps> = () => {
  const [teamName, setTeamName] = useState("");
  const { createTeamError } = useSelector(getUserState);
  const dispatch = useDispatch();

  const sendRequest = useCallback(() => {
    dispatch(createTeam({ teamName }));
  }, [teamName]);
  return (
    <div className="max-w-screen-md mx-auto h-full flex items-center justify-center flex-col">
      <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl mb-6">
        Create a team
      </h2>
      <label
        htmlFor="team"
        className="flex text-sm font-medium text-gray-700 flex-col mb-6"
      >
        Team name
        <input
          type="text"
          name="team"
          value={teamName}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-4 py-2 sm:text-sm border-gray-300 border-2 rounded-md"
          onChange={(e) => setTeamName(e.target.value)}
        />
        {createTeamError && (
          <p className="text-sm text-red-500">{createTeamError}</p>
        )}
      </label>
      <Button className="w-auto" onClick={sendRequest}>
        Create team
      </Button>
    </div>
  );
};

export default NoTeamScreen;
