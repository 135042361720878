import cx from "classnames";
import Link from "next/link";
import { FC, ReactNode } from "react";
import { overrideTailwindClasses as override } from "tailwind-override";

type ButtonProps = {
  size?: "s" | "m" | "l";
  className?: string;
  bgColor?: string;
  href?: string;
  onClick?: any;
  children: ReactNode;
};

const Button: FC<ButtonProps> = ({
  size = "m",
  children,
  className,
  bgColor,
  href,
  onClick,
}) => {
  const button = (
    <button
      type="button"
      {...{ onClick }}
      className={override(
        cx(
          "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full",
          bgColor,
          className,
          {
            "py-6": size === "l",
            "py-4": size === "m",
            "py-2": size === "s",
            "px-8": size === "l",
            "px-6": size === "m",
            "px-4": size === "s",
          }
        )
      )}
    >
      {children}
    </button>
  );
  if (!href && !onClick) {
    throw new Error("No href or onClick defined");
  }
  if (href) {
    return (
      <Link href={href}>
        <a>{button}</a>
      </Link>
    );
  }
  return button;
};

export default Button;
