import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { syncSites } from "../../../redux/slices/sitesSlice";
import { getUserState } from "../../../redux/slices/userSlice";

type NoTeamScreenProps = {};

const SyncSitesPage: FC<NoTeamScreenProps> = () => {
  const { user } = useSelector(getUserState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(syncSites());
  }, []);

  useEffect(() => {
    if (user.team.lastSyncAt) {
      window.parent.history.pushState({}, "", "/done");
    }
  }, [user]);

  return (
    <div className="max-w-screen-md mx-auto h-full flex items-center justify-center flex-col">
      <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl mb-6">
        Loading...
      </h2>
    </div>
  );
};

export default SyncSitesPage;
